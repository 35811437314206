<template>
  <div>
    <div class="dashknw-content">
      <div>
        <h3 style="font-size:17px">
          {{ list.title }}
          <span style="color:#adabab;font-size:12px"
            >{{ " /" + repCode + "/" + list.slug }}
          </span>
        </h3>
        <span class="msg-date">{{ list.length }}m  </span>
         <span style="margin-left:4%" class="msg-date">{{ list.schedulingType ? list.schedulingType : "1-ON-1"  }}</span>
      </div>
      <div class="action-icons">
        <custom-button @onClicked="openModal()" title="Send Email" iconClass="fas fa-envelope" />
        <custom-button
         @onClicked="preview()"
          title="Preview"
          iconClass="fas fa-paper-plane fa-solid"
        />
        <custom-button
          @onClicked="copyEvent()"
          title="Copy Link to Event"
          iconClass="fas fa-link fa-solid"
        />
      </div>
    </div>
    
  </div>
</template>
<script>
import dummyImage from "@/assets/images/user.svg";
export default {
  name: "EventListAll",

  props: {
    list: {
      required: true,
    },
    repCode: {
      required: true,
    },
  },
  data: () => ({
    image: dummyImage,
  }),
  methods: {
    copyEvent() {
      this.$notify("Event URL copied to clipboard");
      navigator.clipboard.writeText(this.getURL());
    },
    getURL() {
      return (
        process.env.VUE_APP_REP_CALENDAR_APP_URL + this.repCode + "/" +this.list.slug
      );
    },
    preview() {
      window.open(this.getURL(), "_blank");
    },
  openModal() {
      this.$emit("modelOpen", this.getURL());
    },
  },
};
</script>
