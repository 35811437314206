<template>
<div class="calendar-list">
  <ul>
    <li
      v-for="k in knowledges"
      :key="k.id"
      :class="listClass(k.id)"
      @click="showKnowledgeData(k)"
    >
      <EventListAll :list="k" :repCode="rep.code" @modelOpen="openModal" />
       
    </li>
    <li v-if="knowledges.length === 0">No event found.</li>
  </ul>
  <SendEventEmail :eventURL="eventURL" />
</div>
</template>
<script>
/*eslint-disable*/
import axios from "axios";
import EventListAll from "@/views/CalendarEvent/partials/EventListAll";
import SendEventEmail from "@/views/CalendarEvent/partials/SendEventEmail.vue";
import { mapGetters } from "vuex";

export default {
  name: "EventList",
  components: {
    EventListAll,
    SendEventEmail
  },
   computed: {
    ...mapGetters({ rep: "rep" }),
  },
  data: () => ({
    knowledges: [],
    showKnowledge: null,
    currentItemId: null,
    eventURL:''
  }),
  methods: {
    listClass(id) {
      let activeClass = this.currentItemId == id ? "active" : "";
      return `knowledge-list ${activeClass}`;
    },
    showKnowledgeData(row) {
      this.showKnowledge = row;
      this.currentItemId = row.id
    },
    getEvents() {
      let url = this.$endpoint.CALENDAR_API_URL
      url+= 'event-type-new'+'?username='+this.rep.code;
      console.log(url);
      let loader = this.$loading.show();
      const otherUrl = axios.create({
  baseURL: "",
});
      otherUrl
        .get(url)
        .then((res) => {
          this.knowledges = res.data.data;
          this.showKnowledge = this.knowledges[0];
          this.currentItemId = this.showKnowledge.id
        })
        .catch(() => {
          this.knowledges = [];
          this.showKnowledge = null;
          this.currentItemId = null
        })
        .finally(() => {
          loader.hide();
          if (this.knowledges.length < 1) {
           /* this.$router.push({ name: "Dashboard" }); */
          }
        });
    },
     openModal(url) {
      this.eventURL = url;
      this.$bvModal.show("send-event-email");
    },
  },
  created() {
    this.getEvents();
  },
};
</script>