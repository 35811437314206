var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "dashknw-content"
  }, [_c('div', [_c('h3', {
    staticStyle: {
      "font-size": "17px"
    }
  }, [_vm._v(" " + _vm._s(_vm.list.title) + " "), _c('span', {
    staticStyle: {
      "color": "#adabab",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(" /" + _vm.repCode + "/" + _vm.list.slug) + " ")])]), _c('span', {
    staticClass: "msg-date"
  }, [_vm._v(_vm._s(_vm.list.length) + "m ")]), _c('span', {
    staticClass: "msg-date",
    staticStyle: {
      "margin-left": "4%"
    }
  }, [_vm._v(_vm._s(_vm.list.schedulingType ? _vm.list.schedulingType : "1-ON-1"))])]), _c('div', {
    staticClass: "action-icons"
  }, [_c('custom-button', {
    attrs: {
      "title": "Send Email",
      "iconClass": "fas fa-envelope"
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.openModal();
      }
    }
  }), _c('custom-button', {
    attrs: {
      "title": "Preview",
      "iconClass": "fas fa-paper-plane fa-solid"
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.preview();
      }
    }
  }), _c('custom-button', {
    attrs: {
      "title": "Copy Link to Event",
      "iconClass": "fas fa-link fa-solid"
    },
    on: {
      "onClicked": function onClicked($event) {
        return _vm.copyEvent();
      }
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }