<template>
  <b-modal
    id="send-event-email"
    centered
    size="md"
    v-model="show"
    no-close-on-backdrop
  >
    <b-button variant="close-btn" @click="closeModal">
      <i class="fas fa-times"></i>
    </b-button>
    <div class="title-text">
      <h5><strong>Send Event URL</strong></h5>
    </div>
    <form @submit.prevent="sendEventUrlEmail">
      <div class="row">
        <div class="col-md-12">
        
               <input-multi-select-tags
                :vmodel.sync="receiver"
                label-name="Emails:"
                :options="options"
                :rules="{ required: false }"
                form-group-class="form-group mb-3"
                placeholder="Emails"
                labelKey="name"
                trackBy="code"
                :options-list="optionsList"
                :is-taggable="true"
                :max-height="200"
                tagPlaceholder="Press enter to add email"
                @onAddTag="addReceiver"
              />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-6">
          <button type="submit" class="univ-btn">Send</button>
        </div>
      </div>
    </form>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import moment from "moment";
import InputMultiSelectTags from "@/components/form/InputMultiSelectTags.vue";

export default {
  name: "SendEmailEvent",
  props: {
    eventURL: {
      required: true,
    },
  },
  components: {
    InputMultiSelectTags,
  },
  data: () => {
    return {
      show: false,
      date: "",
      variants: ["close-btn"],
      appendDatepicker: false,
      receiver:[],
      options:[],
      optionsList:[]
    };
  },
  methods: {
    sendEventUrlEmail() {
      this.showTable = false;
      let url = `v2/send-calendar-event-email`;
      let loader = this.$loading.show();
      Api.post(url,{
          email: this.receiver,
          event_url: this.eventURL,
        })
        .then((res) => {
          this.$notify(res.data.message, "success");
          this.resetTags();
          this.$bvModal.hide("send-event-email");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },

    formattedDate(date) {
      return moment(date).endOf("month").format("YYYY-MM-DD");
    },
    closeModal() {
      this.$emit("onCloseModal");
      this.$bvModal.hide("send-event-email");

      this.resetTags();
    },
    resetTags() {
        this.receiver = []
        //this.options = []

    },
    addReceiver(receiver) {
      let data = {
        name: receiver,
        code: receiver,
      };
      this.options.push(receiver);
      this.receiver.push(receiver);
      this.optionsList.push(data);
    },
  },
  watch: {
    rowId: function () {
      this.date = "";
    },
  },
};
</script>
<style scoped>
.payment-download {
  margin-top: 20px;
}
.title-text {
  margin-bottom: 20px;
}
.invoice-list {
  padding-top: 40px;
}
</style>
<style>
.modal-header,
.modal-footer {
  display: none;
}
.modal-content {
  border: 0px;
  border-radius: 10px;
}
.btn-close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.multiselect {
     width: 100% !important;
     margin-left: 0px !important;
}
</style>
