<template>
  <div class="page-wrapper know-center-wrap knowledge-center-wrap">
    <div class="container-fluid">
      
      <div class="page-title">
        <div>
          <breadcrumb :items="breadcrumbItems" />
          <h1>Calendar Events</h1>
        </div>
        <div class="copylink-block">
          <span class="linkTitle">Copy this link and send it to others to schedule a meeting</span>
          <span class="linkBlock">
            <p>{{getUrl()}}</p>
            <span class="copyIcon" @click="copyEventURL" v-b-tooltip.hover title="Copy Link"><i class="far fa-clone"></i></span>
          </span>
        </div>

      </div>
      <div class="calendarCard">
        <EventList />
      </div>
    </div>
  </div>
</template>
<script>

import EventList from './EventList.vue';
import { mapGetters } from "vuex";

export default {
  name: "CalendarEvent",
  components: {
    EventList,
    
  },
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  data: () => ({
    knowledges: [],
    showKnowledge: null,
    currentItemId: null,
    breadcrumbItems: [{ title: "Calendar Events", active: true }],
    tabIndex: 0,
  }),
  methods: {
     copyEventURL() {
      this.$notify("Event URL copied to clipboard");
      navigator.clipboard.writeText(this.getUrl());
    },
    getUrl() {
        return process.env.VUE_APP_REP_CALENDAR_APP_URL + this.rep.code
    }
  }

};
</script>
<style src="@/assets/css/knowledge.css"></style>
<style src="@/assets/css/calendar.css"></style>
