var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper know-center-wrap knowledge-center-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "page-title"
  }, [_c('div', [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('h1', [_vm._v("Calendar Events")])], 1), _c('div', {
    staticClass: "copylink-block"
  }, [_c('span', {
    staticClass: "linkTitle"
  }, [_vm._v("Copy this link and send it to others to schedule a meeting")]), _c('span', {
    staticClass: "linkBlock"
  }, [_c('p', [_vm._v(_vm._s(_vm.getUrl()))]), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "copyIcon",
    attrs: {
      "title": "Copy Link"
    },
    on: {
      "click": _vm.copyEventURL
    }
  }, [_c('i', {
    staticClass: "far fa-clone"
  })])])])]), _c('div', {
    staticClass: "calendarCard"
  }, [_c('EventList')], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }